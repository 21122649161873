"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
var react_1 = __importStar(require("react"));
var types_1 = require("../types");
var App_1 = require("../App");
var data_1 = require("../data");
var dateutil_1 = require("../dateutil");
var react_i18next_1 = require("react-i18next");
var WIDTH = 500;
var MOUSE_OFFSET = 5;
var EventDetails = function (_a) {
    var event = _a.event, x = _a.x, y = _a.y, onClose = _a.onClose, scrollY = _a.scrollY;
    var _b = (0, react_i18next_1.useTranslation)(), i18n = _b.i18n, t = _b.t;
    var eventDetailsContainerRef = (0, react_1.useRef)(null);
    var _c = (0, react_1.useState)(0), height = _c[0], setHeight = _c[1];
    (0, react_1.useEffect)(function () {
        if (eventDetailsContainerRef.current) {
            setHeight(eventDetailsContainerRef.current.clientHeight);
        }
    });
    var screenWidth = Math.min(document.documentElement.clientWidth, window.innerWidth);
    var screenHeight = Math.min(document.documentElement.clientHeight, window.innerHeight);
    var width = WIDTH;
    if (screenWidth - App_1.MARGIN_SIDE * 5 < width) {
        width = screenWidth - App_1.MARGIN_SIDE * 5;
    }
    // if there is not enough space for the event height, move it
    if (y + height > screenHeight - App_1.MARGIN_SIDE * 2) {
        y = screenHeight - height - App_1.MARGIN_SIDE * 2;
    }
    else if (y < scrollY) {
        y = scrollY;
    }
    if (x + MOUSE_OFFSET + width > screenWidth - App_1.MARGIN_SIDE * 5) {
        x = screenWidth - App_1.MARGIN_SIDE * 4 - width;
    }
    else {
        x += MOUSE_OFFSET;
        y += MOUSE_OFFSET + scrollY;
    }
    return (react_1["default"].createElement("div", { ref: eventDetailsContainerRef, id: "eventDetailsContainer", tabIndex: -1, style: {
            position: "absolute",
            top: y,
            left: x,
            backgroundColor: "white",
            width: width + "px",
            borderRadius: "1px",
            padding: "5px",
            border: "2px solid " + types_1.TYPES[event.type],
            textAlign: "left"
        } },
        react_1["default"].createElement("button", { className: "closeButton", onClick: onClose }, "\u2716"),
        react_1["default"].createElement("h4", null, event.label),
        event.selfEmployed && (react_1["default"].createElement("p", { className: "selfEmployed" },
            react_1["default"].createElement("i", null, t("self-employed")))),
        react_1["default"].createElement("b", null,
            event.place,
            " "),
        " ",
        react_1["default"].createElement("img", { src: (0, data_1.getFlagPath)(event), height: 20 }),
        react_1["default"].createElement("p", null, event.description),
        react_1["default"].createElement("p", null,
            t("duration"),
            " : ",
            (0, dateutil_1.calculateDuration)(event, i18n.language)),
        react_1["default"].createElement("p", null,
            t("timeAllocation"),
            " : ",
            event.timePercentage,
            "%")));
};
exports["default"] = EventDetails;
