"use strict";
exports.__esModule = true;
exports.calculateDuration = exports.nearestJanuary1stTimestamp = exports.tsToYearStr = exports.tsToShortStr = exports.tsToStr = exports.toTs = void 0;
function toTs(dateString) {
    var _a = dateString.split("-").map(Number), year = _a[0], month = _a[1], day = _a[2];
    var date = new Date(year, month - 1, day);
    return date.getTime();
}
exports.toTs = toTs;
function tsToStr(timestamp) {
    var date = new Date(timestamp);
    var year = date.getFullYear();
    var month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    var day = String(date.getDate()).padStart(2, "0");
    return "".concat(year, "-").concat(month, "-").concat(day);
}
exports.tsToStr = tsToStr;
var MONTHS_EN = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];
var MONTHS_FR = [
    "Jan",
    "Fév",
    "Mar",
    "Avr",
    "Mai",
    "Juin",
    "Juil",
    "Août",
    "Sept",
    "Oct",
    "Nov",
    "Déc"
];
function tsToShortStr(timestamp, language) {
    var months = MONTHS_EN;
    if (language.startsWith('fr')) {
        months = MONTHS_FR;
    }
    var date = new Date(timestamp);
    var year = date.getFullYear();
    var month = months[date.getMonth()]; // Get the month abbreviation
    return "".concat(year, " ").concat(month);
}
exports.tsToShortStr = tsToShortStr;
function tsToYearStr(timestamp) {
    var date = new Date(timestamp);
    if (!date) {
        return "";
    }
    return date.getFullYear().toString();
}
exports.tsToYearStr = tsToYearStr;
function nearestJanuary1stTimestamp(ts) {
    var date = new Date(ts);
    var year = date.getFullYear();
    var nextJanuary1st = new Date(year + 1, 0, 1);
    var nearestTimestamp = nextJanuary1st.getTime();
    return nearestTimestamp;
}
exports.nearestJanuary1stTimestamp = nearestJanuary1stTimestamp;
function calculateDuration(event, language) {
    var diff = event.endDate - event.startDate;
    var months = Math.ceil(diff / (1000 * 60 * 60 * 24 * 30));
    var years = Math.floor(months / 12);
    var remainingMonths = months % 12;
    var duration = "";
    if (years > 0) {
        duration += "".concat(years, " ").concat(years === 1 ? (language.startsWith('fr') ? "an" : "year") : (language.startsWith('fr') ? "ans" : "years"));
        if (remainingMonths > 0) {
            duration += " ".concat(remainingMonths, " ").concat(remainingMonths === 1 ? (language.startsWith('fr') ? "mois" : "month") : (language.startsWith('fr') ? "mois" : "months"));
        }
    }
    else {
        duration += "".concat(remainingMonths, " ").concat(remainingMonths === 1 ? (language.startsWith('fr') ? "mois" : "month") : (language.startsWith('fr') ? "mois" : "months"));
    }
    return duration;
}
exports.calculateDuration = calculateDuration;
