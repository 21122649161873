"use strict";
exports.__esModule = true;
exports.timelineDataFr = void 0;
var dateutil_1 = require("./dateutil");
exports.timelineDataFr = [
    {
        label: "Bachelier en informatique de gestion",
        startDate: (0, dateutil_1.toTs)("2009-09-01"),
        endDate: (0, dateutil_1.toTs)("2012-09-01"),
        type: "EDUCATION",
        line: 0,
        description: "Bachelier (Baccalauréat) en informatique de gestion. Principalement axé sur l'architecture logicielle, les structures de données et algorithmes, les mathématiques discrètes et le langage orienté objet (Java).",
        place: "HE Léonard de VINCI, Bruxelles",
        country: "Belgique",
        fullTime: true,
        timePercentage: 100,
        selfEmployed: false,
        flagFilename: "belgium"
    },
    {
        label: "Stage",
        startDate: (0, dateutil_1.toTs)("2012-02-01"),
        endDate: (0, dateutil_1.toTs)("2012-05-01"),
        type: "INTERNSHIP",
        line: 1,
        description: "Pour la dernière année de mon bachelier, nous devions faire un stage de 3 mois. J'ai trouvé une position en Angleterre. Pendant le stage, j'ai principalement travaillé sur une solution pour les téléphones Blackberry en utilisant J2ME.",
        place: "Mobile Worker Plus, Richmond",
        country: "Royaume-Uni",
        fullTime: true,
        timePercentage: 100,
        selfEmployed: false,
        hideDate: true,
        flagFilename: "uk"
    },
    {
        label: "Développeur logiciel chez Mobile Worker Plus",
        startDate: (0, dateutil_1.toTs)("2012-10-01"),
        endDate: (0, dateutil_1.toTs)("2016-08-30"),
        type: "IT_WORK",
        line: 0,
        description: "Après avoir effectué un stage non rémunéré pendant mes études, ils ont décidé de m'embaucher. Je suis rapidement devenu development lead pour leur solution Mobile Worker (back-end et front-end). Les technologies utilisées étaient principalement Java Spring et Java Android.",
        place: "Mobile Worker Plus, Richmond",
        country: "Royaume-Uni",
        fullTime: true,
        timePercentage: 100,
        selfEmployed: false,
        flagFilename: "uk"
    },
    {
        label: "Développeur C++ chez Nobel Biocare",
        startDate: (0, dateutil_1.toTs)("2016-10-01"),
        endDate: (0, dateutil_1.toTs)("2018-09-30"),
        type: "IT_WORK",
        line: 0,
        description: "J'ai travaillé en tant que développeur C++ chez Nobel Biocare pendant 2 ans. J'ai rejoint une équipe de 50 développeurs suivant scrupuleusement le framework Scrum. Nous travaillions sur un logiciel de visualisation dentaire 3D. Les technologies utilisées étaient C++ avec Qt.",
        place: "Medicim/Nobel Biocare, Mechelen",
        country: "Belgique",
        fullTime: true,
        timePercentage: 100,
        selfEmployed: false,
        flagFilename: "belgium"
    },
    {
        label: "Formation d'enseignant",
        startDate: (0, dateutil_1.toTs)("2018-09-01"),
        endDate: (0, dateutil_1.toTs)("2019-06-30"),
        type: "EDUCATION",
        hideDate: true,
        line: 2,
        description: "J'ai terminé mon Certificat d'Aptitudes Pédagogiques à Jury Central, Bruxelles.",
        place: "Jury Central, Bruxelles",
        country: "Belgique",
        fullTime: false,
        timePercentage: 20,
        selfEmployed: false,
        flagFilename: "belgium"
    },
    {
        label: "Professeur d'informatique",
        startDate: (0, dateutil_1.toTs)("2018-10-01"),
        endDate: (0, dateutil_1.toTs)("2019-06-30"),
        type: "SCHOOL",
        line: 0,
        description: "J'ai enseigné l'informatique à l'Institut national de radioélectricité et cinématographie, Bruxelles. J'ai donné un cours sur la programmation logicielle en Python et un autre cours sur l'architecture matérielle.",
        place: "Institut national de radioélectricité et cinématographie, Bruxelles",
        country: "Belgique",
        fullTime: false,
        timePercentage: 66,
        selfEmployed: false,
        hideDate: window.innerWidth < 500,
        flagFilename: "belgium"
    },
    {
        label: "Consultant à distance pour Mobile Worker Plus",
        startDate: (0, dateutil_1.toTs)("2019-01-01"),
        endDate: (0, dateutil_1.toTs)("2024-06-30"),
        hideDate: true,
        type: "IT_WORK",
        line: 1,
        description: "En 2019, j'ai commencé à travailler à distance pour Mobile Worker Plus pendant environ 10 à 20 heures par semaine. Mes tâches consistent principalement à maintenir et améliorer la solution Mobile Worker. Les technologies utilisées sont React/Typescript pour le front-end web, Java/Spring pour le back-end et Java/Android pour le mobile.",
        place: "Mobile Worker Plus, [à distance]",
        country: "Royaume-Uni",
        fullTime: false,
        timePercentage: 40,
        selfEmployed: true,
        flagFilename: "uk"
    },
    {
        label: "Coordinateur chez CodeNPlay",
        startDate: (0, dateutil_1.toTs)("2019-10-01"),
        endDate: (0, dateutil_1.toTs)("2021-08-25"),
        type: "SCHOOL",
        line: 0,
        description: "J'ai travaillé en tant que coordinateur chez CodeNPlay, Bruxelles. Mes tâches consistaient à faciliter des animations sur la robotique dans les écoles primaires, à former et superviser de nouveaux instructeurs et à travailler sur le contenu des cours.",
        place: "CodeNPlay, Bruxelles",
        country: "Belgique",
        fullTime: false,
        timePercentage: 50,
        selfEmployed: false,
        flagFilename: "belgium"
    },
    {
        label: "Enseignant à Saint André",
        startDate: (0, dateutil_1.toTs)("2021-09-01"),
        endDate: (0, dateutil_1.toTs)("2023-06-30"),
        type: "SCHOOL",
        line: 0,
        description: "J'ai donné des cours de programmation à des élèves âgés de 14 à 17 ans. Après un an, je suis devenu titulaire de classe, ce qui signifie que je donnais un suivi académique individualisé à environ 25 élèves.",
        place: "Institut secondaire catholique Saint-André, Bruxelles.",
        country: "Belgique",
        fullTime: false,
        timePercentage: 60,
        selfEmployed: false,
        flagFilename: "belgium"
    },
    {
        label: "Enseignant à Saint Jean",
        startDate: (0, dateutil_1.toTs)("2023-09-01"),
        endDate: (0, dateutil_1.toTs)("2024-06-30"),
        type: "SCHOOL",
        line: 0,
        description: "Je suis actuellement enseignant à l'école catholique Saint Jean. Je suis 'enseignant de gestion', ce qui signifie que j'enseigne des matières supplémentaires que les enseignants principaux ne sont pas disposés à enseigner. En ce moment, j'enseigne principalement l'éducation physique (ce qui est une expérience très intéressante pour moi).",
        place: "École catholique francophone Saint Jean, Ontario, Canada",
        country: "Canada",
        fullTime: false,
        timePercentage: 50,
        selfEmployed: false,
        flagFilename: "canada"
    },
];
